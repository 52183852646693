const YoutubeIcon = () => (
  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.0954 33.7735C26.4014 33.7735 33.9454 26.2295 33.9454 16.9235C33.9454 7.61749 26.4014 0.0734863 17.0954 0.0734863C7.78942 0.0734863 0.245422 7.61749 0.245422 16.9235C0.245422 26.2295 7.78942 33.7735 17.0954 33.7735Z"
      fill="#EFEFEF"
    />
    <g clipPath="url(#clip0_5_1338)">
      <path
        d="M23.1397 9.80347H11.0513C9.15455 9.80347 7.60211 11.4748 7.60211 13.5168V20.3302C7.60211 22.3721 9.15455 24.0435 11.0513 24.0435H23.1397C25.0364 24.0435 26.5888 22.373 26.5888 20.3302V13.5168C26.5888 11.4748 25.0364 9.80347 23.1397 9.80347ZM17.6291 18.9385L14.6832 20.9002V16.9769V13.0526L17.6291 15.0143L20.5749 16.976L17.6291 18.9385Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_5_1338">
        <rect width="18.9867" height="14.24" fill="white" transform="translate(7.60211 9.80347)" />
      </clipPath>
    </defs>
  </svg>
)
export default YoutubeIcon
