const TelegramIcon = () => (
  <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.5241 33.7735C26.8301 33.7735 34.3741 26.2295 34.3741 16.9235C34.3741 7.61749 26.8301 0.0734863 17.5241 0.0734863C8.21813 0.0734863 0.674133 7.61749 0.674133 16.9235C0.674133 26.2295 8.21813 33.7735 17.5241 33.7735Z"
      fill="#EFEFEF"
    />
    <path
      d="M25.3855 10.4921C25.3137 10.8618 25.2441 11.232 25.1696 11.601C24.8211 13.3281 24.4716 15.0549 24.1212 16.7816C23.71 18.8124 23.2988 20.843 22.8878 22.8734C22.8222 23.1974 22.7576 23.5217 22.6918 23.846C22.6846 23.8811 22.675 23.9162 22.6657 23.951C22.4268 24.8493 21.7559 24.9503 21.097 24.4809C20.027 23.7186 18.9802 22.9241 17.9234 22.1432C17.6613 21.9494 17.3954 21.7596 17.1376 21.5611C17.0487 21.4927 16.995 21.503 16.9161 21.58C16.305 22.1762 15.6906 22.7691 15.0729 23.3586C14.9356 23.4921 14.7862 23.6124 14.6265 23.718C14.2848 23.9395 13.9018 23.8503 13.7204 23.4869C13.5828 23.2114 13.4847 22.9144 13.3881 22.6206C12.9472 21.2806 12.5107 19.9391 12.0785 18.5962C12.04 18.4766 11.9806 18.4123 11.8564 18.3741C10.6121 17.9911 9.37145 17.6 8.12679 17.2143C7.90594 17.1459 7.71491 17.044 7.61116 16.8257C7.46455 16.5175 7.5947 16.1519 7.94229 15.9597C8.13052 15.8556 8.33987 15.7882 8.5424 15.7112C11.8909 14.4424 15.2398 13.1742 18.5891 11.9067C20.4159 11.215 22.2433 10.5246 24.0712 9.83548C24.2576 9.76528 24.4545 9.6957 24.6502 9.68297C25.0677 9.65563 25.3429 9.92929 25.3628 10.348C25.3653 10.3946 25.3628 10.4412 25.3628 10.4878L25.3855 10.4921ZM14.2084 22.7082L14.2311 22.7051C14.3429 21.7242 14.4582 20.7435 14.5641 19.7623C14.5849 19.5697 14.6405 19.4225 14.7949 19.2842C17.2163 17.1163 19.6335 14.9439 22.0466 12.7671C22.0848 12.7326 22.1413 12.7006 22.1531 12.6578C22.1774 12.5699 22.1771 12.4751 22.187 12.3835C22.105 12.3767 22.0218 12.3593 21.9413 12.3673C21.8888 12.3726 21.8379 12.4118 21.7897 12.4416C21.5565 12.5865 21.324 12.7315 21.0921 12.8765L13.653 17.5444C13.3424 17.7392 13.0318 17.9349 12.7165 18.1346L14.2084 22.7082Z"
      fill="#01051A"
    />
  </svg>
)
export default TelegramIcon
