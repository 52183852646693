const XIcon = (props) => (
  <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M17.7385 33.7735C27.0445 33.7735 34.5885 26.2296 34.5885 16.9236C34.5885 7.61755 27.0445 0.0735474 17.7385 0.0735474C8.43249 0.0735474 0.888489 7.61755 0.888489 16.9236C0.888489 26.2296 8.43249 33.7735 17.7385 33.7735Z"
      fill="white"
    />
    <path
      d="M14.2485 8.30878L18.9321 14.7262C19.0628 14.588 19.1898 14.4555 19.3141 14.3223C20.0647 13.5223 20.8151 12.7222 21.5654 11.9217C22.643 10.7729 23.7201 9.62361 24.7966 8.4739C24.8702 8.3941 24.9426 8.35651 25.0543 8.35798C25.5293 8.36461 26.0044 8.36093 26.4793 8.36093H26.5999L19.6471 15.7055L26.679 25.3399C26.5586 25.3456 26.4543 25.3514 26.3499 25.3554C25.4198 25.3914 24.4897 25.4271 23.5598 25.4625C22.951 25.4855 22.3421 25.5084 21.7331 25.527C21.7002 25.5279 21.656 25.4955 21.6335 25.466C21.1993 24.8911 20.7666 24.3153 20.3354 23.7385L16.7217 18.9193C16.7069 18.9009 16.6913 18.8814 16.6685 18.8526C16.5295 18.999 16.3936 19.142 16.2578 19.2853C15.4338 20.1552 14.6099 21.0251 13.7859 21.8952C12.7182 23.0234 11.6462 24.1483 10.5862 25.2843C10.4185 25.464 10.256 25.5528 10.0052 25.5366C9.64518 25.5132 9.28254 25.5305 8.92082 25.5305H8.79785L15.9568 17.8999L8.90333 8.49048C9.01599 8.49048 9.10582 8.49325 9.19473 8.49048C10.1861 8.45584 11.1774 8.4207 12.1686 8.38507C12.7594 8.36418 13.35 8.34379 13.9404 8.32389C13.9639 8.32096 13.9871 8.31603 14.0098 8.30914L14.2485 8.30878ZM24.6819 24.3706C24.6504 24.324 24.6348 24.2991 24.6173 24.2753C23.7964 23.1595 22.9755 22.0436 22.1545 20.9277C19.4475 17.2493 16.7421 13.5697 14.0383 9.88886C13.9115 9.71582 13.7806 9.65408 13.5748 9.64689C12.9507 9.62533 12.3274 9.58128 11.704 9.54645C11.5348 9.53705 11.3654 9.52968 11.1801 9.52065C11.208 9.56064 11.2246 9.58552 11.2423 9.60948C12.5896 11.4267 13.9369 13.2438 15.2842 15.0607C17.5701 18.1431 19.8558 21.2257 22.1412 24.3085C22.1898 24.3741 22.2393 24.4006 22.322 24.3993C22.91 24.3866 23.4979 24.3783 24.0859 24.3702C24.2775 24.3684 24.4693 24.3706 24.6819 24.3706Z"
      fill="black"
    />
  </svg>
)

export default XIcon
