/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import styled from 'styled-components'
import Image from 'next/image'
import { Button, Flex, Logo, useMatchBreakpoints } from '@pancakeswap/uikit'
import { NetworkSwitcher } from 'components/NetworkSwitcher'
import { useRouter } from 'next/router'
import { Link } from 'react-scroll'
import { useState } from 'react'
import { Drawer, Dropdown, MenuProps } from 'antd'
import UserWalletWithModal from './UserWalletWithModal'
import MenuIcon from './MenuIcon'
import XIcon from './XIcon'
import TelegramIcon from './TelegramIcon'
import FacebookIcon from './FacebookIcon'
import YoutubeIcon from './YoutubeIcon'

const MENU_HEIGHT = 84

const WrapMenu = styled.div`
  overflow: hidden;
  .nav {
    position: fixed;
    z-index: 20;
    background-color: #000000;
    .logo {
      max-height: 50px;
    }
    padding: 17px 160px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 auto;

    height: 84px;
    ${({ theme }) => theme.mediaQueries.sm} {
      height: 84px;
    }
    @media (max-width: 1025px) {
      padding: 17px 20px;
      .left-menu {
        gap: 10px;
      }
    }
    .ant-dropdown .ant-dropdown-menu,
    .ant-dropdown-menu-submenu .ant-dropdown-menu {
      background-color: #000000 !important;
    }
  }
  .historyMn {
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    position: relative;
    z-index: 10;
    padding: 7px 15px;
    border-radius: 10px;
    background-color: rgb(255, 255, 255, 0.6);
    gap: 10px;
  }
`

const DropdownCustom = styled(Dropdown)`
  .ant-dropdown-menu {
    background-color: #000000 !important;
  }
`

const Container = styled.div``

const InnerBody = styled.div`
  width: 100%;
  // max-width: 1300px;
  padding: 0 16px;
  margin-top: ${MENU_HEIGHT}px;
  height: calc(100vh - ${MENU_HEIGHT}px);
  background-image: url('/images/background/bg.png');
  position: relative;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  color: #fff;
  @media only screen and (max-width: 768px) {
    height: calc(100vh);
    padding-top: ${MENU_HEIGHT}px;
    margin-top: 0px;
  }
`
const Footer = styled.div`
  padding: 0px 10px 40px 10px;
  text-align: center;
  color: #686868;
`

export const StyledButton = styled(Button)`
  width: 148.16px;
  border-radius: 7px;
  color: #fff;
  padding: 2px;
  margin-left: 6px;
  @media only screen and (max-width: 768px) {
    width: 69.53px;
    height: 22.05px;
    opacity: 0px;
  }
`
const StyledConnect = styled.div`
  color: #fff;
  font-size: 25px;
  font-weight: 600;
  line-height: 37.5px;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
  }
`
const Menu = ({ children }) => {
  const router = useRouter()
  const { isDesktop } = useMatchBreakpoints()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }
  const closeMobileMenu = () => {
    if (!isDesktop) {
      setIsMenuOpen(false)
    }
  }

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <a
          href="https://beurl.app/LkSFbhix"
          target="_blank"
          style={{
            color: '#FFF',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 10,
          }}
          rel="noreferrer"
        >
          <XIcon />
        </a>
      ),
    },
    {
      key: '2',
      label: (
        <a
          href="https://beurl.app/tvgSitHv"
          target="_blank"
          style={{
            color: '#FFF',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 10,
          }}
          rel="noreferrer"
        >
          <TelegramIcon />
        </a>
      ),
    },
    {
      key: '3',
      label: (
        <a
          href="https://beurl.app/bpuLTJeD"
          target="_blank"
          style={{
            color: '#FFF',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 10,
          }}
          rel="noreferrer"
        >
          <FacebookIcon />
        </a>
      ),
    },
    {
      key: '4',
      label: (
        <a
          href="https://beurl.app/OEXpORZx"
          target="_blank"
          style={{
            color: '#FFF',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 10,
          }}
          rel="noreferrer"
        >
          <YoutubeIcon />
        </a>
      ),
    },
  ]

  return (
    <WrapMenu>
      <div style={{ display: 'flex', justifyContent: 'space-between' }} className="nav">
        <div style={{ minWidth: 148, minHeight: 50, cursor: 'pointer' }}>
          <Image src="/images/logo/logo.png" alt="avb" width={148} height={50} />
        </div>

        {!isDesktop ? (
          <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
            <StyledButton
              style={{
                backgroundImage: 'linear-gradient(90deg,#FF9D6C,#BB4E75)',
                // background: 'red',
              }}
            >
              <StyledConnect>Log in</StyledConnect>
            </StyledButton>
            <Dropdown
              className="my-dropdown"
              trigger={['hover']}
              menu={{ items, theme: 'dark', style: { backgroundColor: '#000000D9', width: '105px' } }}
              placement="bottomCenter"
            >
              <MenuIcon />
            </Dropdown>
          </div>
        ) : (
          <>
            {' '}
            <Flex className="left-menu" alignItems="center" style={{ gap: 15 }}>
              <a
                href="https://beurl.app/LkSFbhix"
                target="_blank"
                style={{ color: '#FFF', cursor: 'pointer' }}
                rel="noreferrer"
              >
                <XIcon />
              </a>
              <a
                href="https://beurl.app/tvgSitHv"
                target="_blank"
                style={{ color: '#FFF', cursor: 'pointer' }}
                rel="noreferrer"
              >
                <TelegramIcon />
              </a>
              <a
                href="https://beurl.app/bpuLTJeD"
                target="_blank"
                style={{ color: '#FFF', cursor: 'pointer' }}
                rel="noreferrer"
              >
                <FacebookIcon />
              </a>
              <a
                href="https://beurl.app/OEXpORZx"
                target="_blank"
                style={{ color: '#FFF', cursor: 'pointer' }}
                rel="noreferrer"
              >
                <YoutubeIcon />
              </a>
              <StyledButton
                style={{
                  backgroundImage: 'linear-gradient(90deg,#FF9D6C,#BB4E75)',
                  // background: 'red',
                }}
              >
                <div style={{ color: '#fff', fontSize: 25, fontWeight: '600', lineHeight: '37.5px' }}>Log in</div>
              </StyledButton>
            </Flex>
          </>
        )}
      </div>
      <InnerBody>{children}</InnerBody>
      {/* <Footer>Copyright © 2024 Udefi. All rights reserved</Footer> */}
    </WrapMenu>
  )
}

export default Menu
