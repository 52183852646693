const FacebookIcon = () => (
  <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.3098 33.7736C26.6158 33.7736 34.1598 26.2296 34.1598 16.9236C34.1598 7.61755 26.6158 0.0735474 17.3098 0.0735474C8.00378 0.0735474 0.459778 7.61755 0.459778 16.9236C0.459778 26.2296 8.00378 33.7736 17.3098 33.7736Z"
      fill="#EFEFEF"
    />
    <path
      d="M22.2168 18.4443V14.6757H18.5172V12.2394C18.4911 11.9582 18.53 11.6748 18.631 11.4103C18.7321 11.1457 18.8927 10.9067 19.1011 10.711C19.3094 10.5152 19.5601 10.3677 19.8347 10.2794C20.1092 10.191 20.4006 10.1641 20.6872 10.2005H22.3674V6.99871C21.3708 6.8393 20.3634 6.75319 19.3535 6.74109C16.3397 6.74109 14.3203 8.54441 14.3203 11.8125V14.6757H10.9373V18.4443H14.3806V27.5419C15.7615 27.7479 17.1665 27.7479 18.5474 27.5419V18.4443H22.2168Z"
      fill="#01051A"
    />
  </svg>
)

export default FacebookIcon
